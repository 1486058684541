/** @jsxImportSource @emotion/react */
import React, { FC } from 'react'
// import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface Props {
  label: string
  direction?: 'right' | 'left'
  className?: string
  as?: 'button' | 'span'
  action?: () => void
}

export const ButtonArrow: FC<Props> = ({
  label,
  direction = 'right',
  className,
  as = 'button',
  action
}) => {
  const buttonDirection = `__${direction}`

  return as !== 'span' && action ? (
    <button
      className={`${buttonDirection} ${className}`}
      type='button'
      css={buttonStyle}
      onClick={() => {
        action()
      }}
    >
      <span className='block'>{label}</span>
    </button>
  ) : (
    <span className={`${buttonDirection} ${className}`} css={buttonStyle}>
      <span className='block'>{label}</span>
    </span>
  )
}

const buttonStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  height: auto;
  font-size: 0.75rem;
  color: var(--color-black);
  letter-spacing: 0.1em;
  line-height: 2;
  cursor: pointer;
  &.__right {
    padding-right: 48px;
    transition: padding-right 0.2s linear;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%) rotate(180deg);
      width: 4.5px;
      height: 8px;
      background: center/contain
        url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNSA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTMuODAyMyAwTDQuNSAwLjczMzk3OUwxLjM5NTQgNEw0LjUgNy4yNjYwMkwzLjgwMjMgOEwwIDRMMy44MDIzIDBaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K')
        no-repeat;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      width: 40px;
      height: 1px;
      background: var(--color-black);
      transition: width 0.2s linear;
    }
    &:hover {
      padding-right: 40px;
      transition: padding-right 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      &::after {
        width: 32px;
        transition: width 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      }
    }
  }
  &.__left {
    padding-left: 48px;
    transition: padding-left 0.2s linear;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 4.5px;
      height: 8px;
      background: center/contain
        url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNSA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTMuODAyMyAwTDQuNSAwLjczMzk3OUwxLjM5NTQgNEw0LjUgNy4yNjYwMkwzLjgwMjMgOEwwIDRMMy44MDIzIDBaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K')
        no-repeat;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 40px;
      height: 1px;
      background: var(--color-black);
      transition: width 0.2s linear;
    }
    &:hover {
      padding-left: 40px;
      transition: padding-left 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      &::after {
        width: 32px;
        transition: width 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      }
    }
  }
`
