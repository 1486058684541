import React, { FC, useEffect, useState } from 'react'
import Link from 'next/link'
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { AldeaNews, PublicNews } from '@/features/content/types'
import dayjs from 'dayjs'
interface Props {
  news: PublicNews | AldeaNews
  path: 'aldea/news' | 'news'
  dateColor?: string
  className?: string
}

export const ContentNews: FC<Props> = ({ news, path, className, dateColor }) => {
  const textColor = `__${dateColor}`
  // Uncaught Error: Minified React error 回避のためにdateStringに日付を変換した値をuseEffectで設定する
  const [dateString, setDateString] = useState('')
  useEffect(() => {
    setDateString(dayjs(news.date).format('ll'))
  }, [dateString, news.date])
  return (
    <ContentWrap className={`${className} ${textColor}`}>
      <Link href={`/${path}/${news.id}/`}>
        <a className='itemContent'>
          <span className='itemDate'>{dateString}</span>
          <span className='itemTitle font-12px'>{news.title}</span>
        </a>
      </Link>
    </ContentWrap>
  )
}

const ContentWrap = styled.article`
  background: var(--color-white);
  &:hover {
    background: var(--color-beige);
  }
  &.__white {
    background: var(--color-beige);
    &:hover {
      background: #ece5e1;
    }
    .itemDate {
      color: var(--color-white);
    }
  }
  .itemContent {
    display: flex;
    gap: 0 32px;
    padding: 16px 0;
    border-top: 1px var(--color-black) solid;
  }
  .itemDate {
    font-size: 0.75rem;
    color: var(--color-gray-500);
    letter-spacing: 0.1em;
    line-height: 2;
  }
  .itemTitle {
    flex: 1;
  }
`
