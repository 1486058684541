import Head from 'next/head'
import * as React from 'react'
import { useRouter } from 'next/router'

type Props = {
  title: string
  description?: string
  keyword?: string
  type: string
}

export const CommonMeta: React.FC<Props> = (props) => {
  const router = useRouter()
  const { title, description, keyword, type } = props
  const domain = process.env.NEXT_PUBLIC_SITE_DOMAIN
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='description' content={description ?? ''} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description ?? ''} />
        <meta name='keywords' content={keyword ?? ''} />
        <meta property='og:type' content={type} />
        <meta property='og:url' content={`${domain}${router.pathname}`} />
        <meta property='og:image' content={`${domain}/img/ogp.jpg`} />
        <meta property='og:site_name' content='Hz-Village' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:url' content='@akinan_official' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description ?? ''} />
        <meta name='twitter:image' content={`${domain}/img/ogp.jpg`} />
        <link rel='canonical' href={`${domain}${router.pathname}`} />
      </Head>
    </>
  )
}
