import { useEffect, useState } from 'react'

export const useToggleHeaderColor = (defaultY: number) => {
  const [y, setY] = useState(defaultY)
  const [headerColor, setHeaderColor] = useState<'black' | 'white'>('white')

  useEffect(() => {
    const toggleHeaderColor = () => {
      window.scrollY > y ? setHeaderColor('black') : setHeaderColor('white')
    }
    const setInnerHeight = () => {
      setY(window.innerHeight)
    }
    window.addEventListener('scroll', toggleHeaderColor)
    window.addEventListener('resize', setInnerHeight)
    return () => {
      window.removeEventListener('scroll', toggleHeaderColor)
      window.removeEventListener('resize', setInnerHeight)
    }
  }, [y])

  useEffect(() => {
    setY(window.innerHeight)
  }, [y, setY])

  return {
    headerColor
  }
}
